import React from 'react'
import OrderGrid from '../../../../components/OrderGrid '
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import SquareFootRoundedIcon from '@mui/icons-material/SquareFootRounded';
import { SizeColumn } from './constants/MarketPlaceColumn'
import { useFetchData } from '../../../../hooks/useFetchData'

export const Sizes = ({ pageTitle }) => {
  const { data, loading, error } = useFetchData("/category/size/");

  const navigate = useNavigate()

  return (
    <>
      <div className="containerr">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Cтатистика' initial='Список размеров' />
        <Title
          title={'Список размеров'}
          icon={<SquareFootRoundedIcon fontSize='24' />}
        />

        <div className="container-inner">
          {error ? <h1>Error :(</h1> : (
            <div className="orders-card-wrapper">
              <div className="orders-card-header">
                <div className="rders-card-parent">
                  <div className="orders-card-parent__btns">
                    <Button variant='black-contained' onClick={() => navigate('/sizes/add')}>
                      Добавить +
                    </Button>
                  </div>
                </div>
              </div>
              <OrderGrid
                orders={data}
                columns={SizeColumn}
                loading={loading}
                size={20}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { handleSignOut } from '../../configs/index';
import LogoutRoundedIcon from '@mui/icons-material/LogoutOutlined';
import Logo from '../../assets/images/logo.svg';
import './Navbar.scss';
import { List, ListItemButton, ListItemText, ListItemIcon, Collapse } from '@mui/material';
import {
  orderLinks,
  accountingLinks,
  couriersLinks,
  marketplaceLinks,
  otherLinks,
  mainLinks
} from './constants';
import { MdKeyboardArrowRight, MdOutlineExpandMore } from 'react-icons/md'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';

import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';

const NavbarCollapse = () => {
  const [openSections, setOpenSections] = React.useState({
    profile: false,
    dashboard: true,
    search: true,
    allOrders: true,
    addOrder: true,
    orders: true,
    accounting: true,
    couriers: true,
    marketplace: true,
    other: true,
  });

  const handleToggleSection = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const navLinkStyles = ({ isActive }) => ({
    width: '100%',
    // backgroundColor: isActive ? '#0c35e920' : '',
    // color: isActive ? '#3A46D6' : '#637381',
    backgroundColor: isActive ? '#7071741f' : '',
    color: '#637381',
    transition: 'background-color 0.3s ease',
    '& .MuiCollapse-root': {
      backgroundColor: 'red',
      display: 'none'
    }
  });


  const renderSection = (links, isOpen, sectionName, icon, title) => (
    <>
      <ListItemButton
        onClick={() => handleToggleSection(sectionName)}
        className={isOpen ? 'list-button' : 'list-button active'}
        sx={{ width: '100%' }}
      >
        <ListItemIcon className="list-button-icon">{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {isOpen ? <MdKeyboardArrowRight size={18} /> : <MdOutlineExpandMore size={18} />}
      </ListItemButton>
      {links.map(({ path, title, iconB }) => (
        <NavLink to={path} className="link" style={navLinkStyles} key={path}>
          <Collapse in={!isOpen} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
            <List component="div" disablePadding>
              <ListItemButton className="list-li" sx={{ py: '4px', width: '100%' }}>
                <ListItemIcon className='list-li-icon'>{iconB}</ListItemIcon>
                <ListItemText primary={title} />
              </ListItemButton>
            </List>
          </Collapse>
        </NavLink>
      ))}
    </>
  );


  const bottomNavSections = [
    {
      links: mainLinks,
      isOpen: openSections.main,
      sectionName: 'main',
      icon: <WindowOutlinedIcon />,
      title: 'Главные'
    },
    {
      links: orderLinks,
      isOpen: openSections.orders,
      sectionName: 'orders',
      icon: <AssignmentOutlinedIcon />,
      title: 'Заказы (списки)'
    },
    {
      links: accountingLinks,
      isOpen: openSections.accounting,
      sectionName: 'accounting',
      icon: <MonetizationOnOutlinedIcon />,
      title: 'Бухгалтерия'
    },
    {
      links: couriersLinks,
      isOpen: openSections.couriers,
      sectionName: 'couriers',
      icon: <PeopleAltOutlinedIcon />,
      title: 'Курьеры'
    },
    {
      links: marketplaceLinks,
      isOpen: openSections.marketplace,
      sectionName: 'marketplace',
      icon: <StoreMallDirectoryOutlinedIcon />,
      title: 'Маркетплейс'
    },
    {
      links: otherLinks,
      isOpen: openSections.other,
      sectionName: 'other',
      icon: <AttachmentOutlinedIcon />,
      title: 'Другое'
    },
  ];

  return (
    <>
      <nav className='nav scrollable' style={{ overflowY: 'scroll' }}>
        <div className='nav-list-wrapper'>
          <div className='nav-logo'>
            <img src={Logo} alt='LOGO' />
          </div>
          <hr />
          <div className="nav-list">
            <List sx={{ width: '100%', color: 'white' }} component="ul" className='nav-list-list'>
              {bottomNavSections.map(({ links, isOpen, sectionName, icon, title }) =>
                renderSection(links, isOpen, sectionName, icon, title)
              )}
            </List>
          </div>
          <ListItemButton sx={{ pl: 4, width: '100%' }} className='navbar-signOut' onClick={handleSignOut}>
            <ListItemIcon className='list-button-icon'><LogoutRoundedIcon /></ListItemIcon>
            <ListItemText primary='Выйти' />
          </ListItemButton>
        </div>
      </nav >
    </>
  );
};
export default NavbarCollapse;



{/* <ul className='nav-list'>
{arrSidebarList?.map(({ id, icon, path, title }, index) => (
  <NavLink
    to={path}
    className='link'
    style={navLinkStyles2}
    key={index}
  >
    <li>
      <span>{icon}</span>
      <span className='nav-list__text'>{title}</span>
    </li>
  </NavLink>
))}
</ul> */}
import { Button } from '@mui/material';
import * as XLSX from 'xlsx';
import { headersForCompletedOrders, returnedOrderStatusLabels } from '../../Orders/constants';
import { packageTypeLabels, statusLabels } from '../../../../../utils';
import { dateFormatter } from '../../../../../utils/dateFormatter';

export const ExportToExcelButtons = ({ ordersData }) => {
  const ordersLabels = { ...statusLabels, ...returnedOrderStatusLabels }
  const exportToExcel = () => {
    const data = ordersData.map((order) => [
      dateFormatter(order?.dateCreated?.seconds),
      dateFormatter(order?.dateDelivered?.seconds),
      !order.pid ? 'Отсутствует' : order.pid,
      !order.courierOne ? 'Отсутствует' : order.courierOne,
      !order.courierTwo ? 'Отсутствует' : order.courierTwo,
      ordersLabels[order.status],
      packageTypeLabels[order.packageType],

      order.addressFrom?.cityName || null,
      `${order.senderName}, (${order.sender})`,

      order.addressTo?.cityName || null,
      `${order.receiverName},(${order.receiverPhone})`,

      !order.cost ? `${order.tariff?.cost}` : `${order.cost}`,
      `${order?.redemption}`,
      (() => {
        switch (order?.whoPays) {
          case 1:
            return 'Отправитель';
          case 2:
            return 'Получатель';
          default:
            return 'Совместно';
        }
      })(),
      `${order?.senderPays === null ? '0.0' : order?.senderPays}`,
    ]);

    const finalData = [headersForCompletedOrders, ...data];
    const ws = XLSX.utils.aoa_to_sheet(finalData);
    ws['!cols'] = Array(finalData[0].length).fill({ wch: 22 });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Список всех заказов');

    XLSX.writeFile(wb, 'Список всех заказов.xlsx');
  };

  return (
    <Button variant='black-contained' onClick={exportToExcel}>
      Экспорт в Excel
    </Button>
  );
};

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  CircularProgress,
  Backdrop,
  Stack,
} from '@mui/material';
import axiosInstance from '../../../../configs/axiosInstance';
import { Helmet } from 'react-helmet';
import { Header } from '../../../../components/Header/Header';
import { Title } from '../../../../components/Title/Title';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { userCollectionRef } from '../../../../utils/collectionRefs';
import { addDoc } from 'firebase/firestore';

const AddColor = ({ pageTitle }) => {
  const [open, setOpen] = useState(false);
  const { handleSubmit, reset, control, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName')

  const handleAddColor = async (data) => {
    setOpen(true);
    const historyData = {
      date: new Date(),
      description: `${userType} ${userName} добавил размер ${data.name}`
    }
    console.log(data)
    try {
      await axiosInstance.post('/category/color/', data);
      await addDoc(userCollectionRef, historyData)

      toast.success('Цвет успешно добавлен', {
        autoClose: 2000,
        hideProgressBar: false,
      });

      setOpen(false);
      navigate('/colors');
      reset();
    } catch (e) {
      setOpen(false);
      toast.error('Произошла ошибка при добавлении цвета', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };
  return (
    <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Статистика' initial='Добавить цвет' />
      <Title title='Добавить цвет' icon={<AddCircleRoundedIcon fontSize='meduim' />} />
      <div className="container-inner">
        <div className="addCourier-wrapper kassa">
          <div className="order-block-head">
            <h3>добавить цвет</h3>
          </div>
          <form onSubmit={handleSubmit(handleAddColor)} className='add-courier-form'>
            <Stack direction='column' gap={2}>
              <Controller
                name='nameRu'
                control={control}
                defaultValue=''
                rules={{ required: 'Обязательное поле!' }}
                render={({ field }) => (
                  <TextField
                    label='Название'
                    variant='outlined'
                    size='small'
                    helperText={errors?.nameRu?.message}
                    error={errors?.nameRu && true}
                    {...field}
                  />
                )}
              />
              <Controller
                name='nameKg'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    label='Аталышы'
                    variant='outlined'
                    size='small'
                    helperText={errors?.nameEn?.message}
                    error={errors?.nameEn && true}
                    {...field}
                  />
                )}
              />
              <Controller
                name='nameEn'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    label='Name'
                    variant='outlined'
                    size='small'
                    helperText={errors?.nameEn?.message}
                    error={errors?.nameEn && true}
                    {...field}
                  />
                )}
              />
              <Controller
                name='color'
                control={control}
                defaultValue=''
                rules={{ required: 'Обязательное поле!' }}
                render={({ field }) => (
                  <TextField
                    label='Цвет (HEX)'
                    variant='outlined'
                    type='color'
                    size='large'
                    helperText={errors?.color?.message}
                    error={errors?.color && true}
                    {...field}
                  />
                )}
              />
              <Button
                type='submit'
                size='large'
                variant='contained'
                style={{ background: '#000b1f' }}
                sx={{ marginTop: '8px' }}
              >
                Добавить Цвет
              </Button>
            </Stack>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color='inherit' />
            </Backdrop>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddColor
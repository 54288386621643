import React from 'react'
import OrderGrid from '../../../../components/OrderGrid '
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import { useFetchData } from '../../../../hooks/useFetchData'
import { SubSubCategoryColumn } from './constants/MarketPlaceColumn'
import NoData from './../../../../components/NoData/NoData';

export const SubSubCategories = ({ pageTitle }) => {
  const navigate = useNavigate()
  const { data, loading, error } = useFetchData("/category/subSubCategory/");
console.log(data)
  return (
    <>
      <div className="containerr">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Cтатистика' initial='Список подподкатегорий' />
        <Title title={'Список подподкатегорий'} icon={<CategoryRoundedIcon fontSize='24' />} />
        <div className="container-inner">
          {error ? <h1>Error :(</h1> : (
            <div className="orders-card-wrapper">
              <div className="orders-card-header">
                <div className="orders-card-parent">
                  <div className="orders-card-parent__btns">
                    <Button variant='black-contained' onClick={() => navigate('/subSubCategories/add')}>
                      Создать +
                    </Button>
                  </div>
                </div>
              </div>
              <OrderGrid
                orders={data}
                columns={SubSubCategoryColumn}
                loading={loading}
                size={20}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

import React, { useEffect } from 'react';
import * as LayoutPages from '../../apps/Layout/Pages';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth } from '../../provider/useAuth';
import Navbar from '../../components/Navbar/Navbar';
import './general.scss';
import NavbarCollapse from './../../components/Navbar/NavbarCollapse';

const PrivateRoutes = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/auth/select-role');
    }
  }, [currentUser, navigate]);

  return (
    <>
      <div className='general-container'>
        {/* <Navbar /> */}
        <NavbarCollapse />
        <Routes>
          <Route
            index
            element={<LayoutPages.Profile pageTitle='Профиль' />}
          />
          <Route
            path='dashboard'
            element={<LayoutPages.Dashboard pageTitle='Статистика' />}
          />
          <Route
            path='search'
            element={<LayoutPages.Search pageTitle='Искать везде' />}
          />
          <Route
            path='allOrders'
            element={<LayoutPages.AllOrders pageTitle='Все заказы' />}
          />
          <Route
            path='orders'
            element={<LayoutPages.Orders pageTitle='Активные заказы в реальном времени' />}
          />
          <Route
            path='returned-orders'
            element={<LayoutPages.ReturnedOrders pageTitle='Список возвратов' />}
          />
          <Route
            path='order/returned/details/:id'
            element={<LayoutPages.ReturnedOrderDetail pageTitle='Детали возврата' />}
          />
          <Route
            path='orderss'
            element={<LayoutPages.Orderss pageTitle='Активные заказы' />}
          />
          <Route
            path='order/completed'
            element={<LayoutPages.CompletedOrders pageTitle='Список завершенных заказов' />}
          />
          <Route
            path='order/completed/details/:id'
            element={<LayoutPages.CompletedOrderDetail pageTitle='Детали завершенных заказов' />}
          />
          <Route
            path='order/cancelled'
            element={<LayoutPages.CancelledOrders pageTitle='Список отмененных заказов' />}
          />
          <Route
            path='order/deleted'
            element={<LayoutPages.DeletedOrders pageTitle='Список удаленных заказов' />}
          />
          <Route
            path='order/cancelled/details/:id'
            element={<LayoutPages.CanceledOrdersDetail pageTitle='Детали отменненого заказа' />}
          />
          <Route
            path='longorders'
            element={<LayoutPages.LongOrders pageTitle='Список долгих заказов' />}
          />
          <Route
            path='schetFaktura'
            element={<LayoutPages.OrderShetFaktura pageTitle='Список заказов (Счет фактура)' />}
          />
          <Route path='order/debt'
            element={<LayoutPages.OrdersDebt pageTitle='Список долгов' />}
          />
          <Route
            path='order/add'
            element={<LayoutPages.AddOrder pageTitle='Добавить заказ' />}
          />
          <Route
            path='order/details/:id'
            element={<LayoutPages.OrderDetail pageTitle='Детали заказа' />}
          />
          <Route
            path='order/update/:id'
            element={<LayoutPages.EditOrder pageTitle='Редактирование заказа' />}
          />
          <Route
            path='order/history/:collectionId/:id'
            element={<LayoutPages.History pageTitle='История заказа' />}
          />

          <Route
            path='order/notes/:id'
            element={<LayoutPages.Notes pageTitle='Заметки заказа' />}
          />
          <Route
            path='userHistory/:role/:id'
            element={<LayoutPages.UserHistory pageTitle='История пользователя' />}
          />
          <Route
            path='clients'
            element={<LayoutPages.Clients pageTitle='Список клиентов' />}
          />
          <Route
            path='client/details/:id'
            element={<LayoutPages.ClientDetail pageTitle='Детали клиента' />}
          />
          <Route
            path='courier/add'
            element={<LayoutPages.AddCourier pageTitle='Добавить курьера' />}
          />
          <Route
            path='couriers'
            element={<LayoutPages.Couriers pageTitle='Список курьеров' />}
          />
          <Route
            path='courier/map'
            element={<LayoutPages.CourierMap pageTitle='Карта курьеров' />}
          />
          <Route
            path='courier/details/:id'
            element={<LayoutPages.CourierDetail pageTitle='Детали курьера' />}
          />
          <Route
            path='courier/update/:id'
            element={<LayoutPages.UpdateCourier pageTitle='Редактирование курьера' />}
          />




          <Route
            path='agent/add'
            element={<LayoutPages.AddAgent pageTitle='Добавление агента' />}
          />
          <Route
            path='agents'
            element={<LayoutPages.Agents pageTitle='Список агентов' />}
          />
          <Route
            path='agent/details/:id'
            element={<LayoutPages.AgentDetail pageTitle='Детали агента' />}
          />
          <Route
            path='agent/update/:id'
            element={<LayoutPages.AgentEdit pageTitle='Редактирование агента' />}
          />



          <Route
            path='transaction'
            element={<LayoutPages.Transaction pageTitle='Список транзакций' />}
          />
          <Route
            path='transaction/update/:id'
            element={<LayoutPages.TransactionUpdate pageTitle='Редактирование транзакциb' />}
          />
          <Route
            path='kassa'
            element={<LayoutPages.Kassa pageTitle='Список касс' />}
          />
          <Route
            path='kassa/details/:id'
            element={<LayoutPages.KassaDetails pageTitle='Детали кассы' />}
          />
          <Route
            path='kassa/add'
            element={<LayoutPages.AddKassa pageTitle='Добавить кассу' />}
          />
          <Route
            path='kassa/update/:id'
            element={<LayoutPages.EditKassa pageTitle='Редактирование кассы' />}
          />
          <Route
            path='cities'
            element={<LayoutPages.Cities pageTitle='Список городов' />}
          />
          <Route
            path='city/details/:id'
            element={<LayoutPages.CityDetail pageTitle='Детали города' />}
          />
          <Route
            path='cityOrders'
            element={<LayoutPages.CityOrder pageTitle='Список заказов (Город)' />}
          />
          <Route
            path='cityOrder/add'
            element={<LayoutPages.AddCityOrder pageTitle='Добавить заказ (Город)' />}
          />
          <Route
            path='cityOrder/details/:id'
            element={<LayoutPages.CityOrderDetail pageTitle='Детали заказа (Город)' />}
          />
          <Route
            path='cityOrder/update/:id'
            element={<LayoutPages.EditCityOrder pageTitle='Редактирование заказа (Город)' />}
          />
          <Route
            path='cityOrder/history/:id'
            element={<LayoutPages.HistoryCity pageTitle='История заказа (Город)' />}
          />
          {/* <Route path='city/add' element={<LayoutPages.AddCity />} /> */}
          <Route
            path='city/update/:id'
            element={<LayoutPages.CityUpdate pageTitle='Редактирование города' />}
          />
          <Route
            path='village/update/:id'
            element={<LayoutPages.VillageUpdate pageTitle='Редактирование района' />}
          />
          <Route
            path='faq'
            element={<LayoutPages.FAQ pageTitle='Часто задаваемые вопросы' />}
          />
          <Route
            path='stores'
            element={<LayoutPages.Stores pageTitle='Магазины' />}
          />
          <Route
            path='store/add'
            element={<LayoutPages.StoreAdd pageTitle='Добавление магазина' />}
          />
          <Route
            path='store/details/:id'
            element={<LayoutPages.StoreDetail pageTitle='Детали магазина' />}
          />
          <Route
            path='store/update/:id'
            element={<LayoutPages.StoreEdit pageTitle='Редактирование магазина' />}
          />
          <Route
            path='storeCategories'
            element={<LayoutPages.StoreCategories pageTitle='Категории магазинов' />}
          />
          <Route
            path='storeCategory/add'
            element={<LayoutPages.StoreCaregoryAdd pageTitle='Добавить категорию магазина' />}
          />
          <Route
            path='storeCategory/update/:id'
            element={<LayoutPages.StoreCategoryEdit pageTitle='Редактирование категории магазина' />}
          />
          <Route
            path='categories'
            element={<LayoutPages.Categories pageTitle='Категории' />}
          />
          <Route
            path='categories/add'
            element={<LayoutPages.AddCategory pageTitle='Добавить категорию' />}
          />
          <Route
            path='subCategories'
            element={<LayoutPages.SubCategories pageTitle='Подкатегории' />}
          />
          <Route
            path='subCategories/add'
            element={<LayoutPages.AddSubCategory pageTitle='Добавить подкатегорию' />}
          />
          <Route
            path='subSubCategories'
            element={<LayoutPages.SubSubCategories pageTitle='Подподкатегории' />}
          />
          <Route
            path='subSubCategories/add'
            element={<LayoutPages.AddSubSubCategory pageTitle='Добавить подподкатегорию' />}
          />
          <Route
            path='colors'
            element={<LayoutPages.Colors pageTitle='Цвета' />}
          />
          <Route
            path='colors/add'
            element={<LayoutPages.AddColor pageTitle='Добавить цвет' />}
          />
          <Route
            path='sizes'
            element={<LayoutPages.Sizes pageTitle='Размеры' />}
          />
          <Route
            path='sizes/add'
            element={<LayoutPages.AddSize pageTitle='Добавить размер' />}
          />
          {/* <Route path='village/add' element={<LayoutPages.AddVillage />} /> */}
          <Route
            path='*'
            element={<LayoutPages.NotFound />}
          />
        </Routes>
      </div>
    </>
  );
};

export default PrivateRoutes;

// import { IconButton, Tooltip } from "@mui/material";
// import { MdEditNotifications } from "react-icons/md";
// import { Link } from "react-router-dom";
// import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';

export const CategoriesColumn = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "icon",
    headerName: "Иконка",
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <img
        src={params.value}
        alt="Иконка"
        style={{ width: "40px", height: "40px" }}
      />
    ),
  },
  {
    field: "name",
    headerName: "Название",
    flex: 1,
  },

  {
    field: "priority",
    headerName: "Приоритет",
    width: 120,
    sortable: true,

  },
  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`subCategories/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];

export const SubCategoryColumn = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "icon",
    headerName: "Иконка",
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <img
        src={params.value}
        alt="Иконка"
        style={{ width: "40px", height: "40px" }}
      />
    ),
  },
  {
    field: "name",
    headerName: "Название",
    flex: 1,
  },
  {
    field: "category",
    headerName: "Категория",
    flex: 1,
    renderCell: (params) => (
      <div>
        <p>{params?.value.name}</p>
      </div>
    ),
  },

  {
    field: "priority",
    headerName: "Приоритет",
    width: 120,
    sortable: true,

  },
  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`subCategories/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];
export const SubSubCategoryColumn = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },

  {
    field: "name",
    headerName: "Название",
    flex: 1,
  },
  {
    field: "subCategory",
    headerName: "Подкатегория",
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <div>
        <p>{params?.value.name}</p>
      </div>
    ),
  },

  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`subCategories/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];

export const ColorColumn = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "nameRu",
    headerName: "Название",
    flex: 1,
  },
  {
    field: "nameKg",
    headerName: "Аталышы",
    flex: 1,
  },
  {
    field: "nameEn",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "color",
    headerName: "Цвет",
    width: 120,
    sortable: false,
    renderCell: (params) => (
      <div
        style={{
          width: "40px",
          height: "40px",
          backgroundColor: params.value,
          borderRadius: "50%",
        }}
      />
    ),
  },
  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`colors/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];

export const SizeColumn = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "icon",
    headerName: "Иконка",
    sortable: false,
    width: 100,
    renderCell: (params) => (
      <img
        src={params.value}
        alt="Иконка"
        style={{ width: "40px", height: "40px" }}
      />
    ),
  },
  {
    field: "name",
    headerName: "Название",
    flex: 0.5,
    sortable: true,
  },
  {
    field: "desc",
    sortable: false,
    headerName: "Описание",
    flex: 1,
  },
  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`sizes/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];

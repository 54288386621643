import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  CircularProgress,
  Backdrop,
  Stack,
} from '@mui/material';
import axiosInstance from '../../../../configs/axiosInstance';
import { Helmet } from 'react-helmet';
import { Header } from '../../../../components/Header/Header';
import { Title } from '../../../../components/Title/Title';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { userCollectionRef } from '../../../../utils/collectionRefs';
import { addDoc } from 'firebase/firestore';
import { uploadImage } from './../Stores/helpers/uploadImage';

const AddCategory = ({ pageTitle }) => {
  const [open, setOpen] = useState(false);
  const { handleSubmit, reset, control, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const [imagePreview, setImagePreview] = useState(null);
  const [imgLoading, setImgLoading] = useState(false)

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImgLoading(true);
      try {
        const imageUrl = await uploadImage(file, file.name);
        setImagePreview(imageUrl);
      } catch (error) {
        console.error(error);
      } finally {
        setImgLoading(false);
      }
    }
  };

  const handleCancelImage = () => {
    setImagePreview(null);
  };


  const handleAddSubCategory = async (data) => {
    setOpen(true);

    const subCategoryData = {
      ...data,
      priority: parseInt(data.priority) || 0,
      icon: imagePreview,
    };
    const historyData = {
      date: new Date(),
      description: `${userType} ${userName} добавил категории ${data.name}`,
    }
    try {
      await axiosInstance.post('/category/category/', subCategoryData);
      await addDoc(userCollectionRef, historyData)

      toast.success('Категория успешно добавлена', {
        autoClose: 2000,
        hideProgressBar: false,
      });

      navigate('/categories');
    } catch (e) {
      toast.error('Произошла ошибка при добавлении категории', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } finally {
      setOpen(false);
      reset();
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Статистика' initial='Добавить категорию' />
      <Title title='Добавить категорию' icon={<AddCircleRoundedIcon fontSize='meduim' />} />
      <div className="container-inner">
        <Stack direction='row' alignItems={'center'} gap={4}>
          <div className="addCourier-wrapper kassa">
            <div className='order-block-head'>
              <h3>добавление категории</h3>
            </div>
            <form className='add-courier-form' onSubmit={handleSubmit(handleAddSubCategory)}>
              <Stack direction='column' gap={2}>
                <Controller
                  name='name'
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Обязательное поле!' }}
                  render={({ field }) => (
                    <TextField
                      label='Название'
                      variant='outlined'
                      size='small'
                      helperText={errors?.name?.message}
                      error={errors?.name && true}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='priority'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <TextField
                      label='Приоритет'
                      variant='outlined'
                      size='small'
                      type='number'
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="icon"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Stack direction='row' justifyContent='space-between' alignItems={'center'}>
                      <Button
                        variant="outlined"
                        component="label"
                        size="small"
                        sx={{ marginTop: 1 }}
                      >
                        {imgLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          'Выберите фото'
                        )}
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </Button>
                      <input {...field} type="hidden" value={imagePreview || field.value} />
                    </Stack>
                  )}
                />
                <Button
                  type='submit'
                  size='large'
                  variant='contained'
                  style={{ background: '#000b1f' }}
                  sx={{ marginTop: '8px' }}
                >
                  Создать
                </Button>
              </Stack>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
              >
                <CircularProgress color='inherit' />
              </Backdrop>
            </form>
          </div>
          {imagePreview && (
            <Stack>
              <span>Текущее фото</span>
              <div style={{ width: '300px', height: 'auto', borderRadius: '8px', marginTop: '4px' }} >
                <img
                  src={imagePreview}
                  alt="Предпросмотр"
                  style={{ width: '100%', objectFit: 'contain', borderRadius: '8px' }}
                />
              </div>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleCancelImage}
              >
                Отменить
              </Button>
            </Stack>
          )}
        </Stack >
      </div>
    </div>
  );
};
export default AddCategory
